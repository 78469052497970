html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  font-family: "Roboto Slab", monospace !important;
}

#hero {
  margin: 0 auto;
  background: url("../src/assets/images/background-lg.png");
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  color: white;
}

.hero-title {
  font-size: 3rem;
  font-weight: 700;
  text-align: left;
}

.hero-button {
  margin-left: 20%;
}

.text-color-main {
  color: #3127e8;
}

.carousel img {
  min-width: 100%;
  height: auto;
}

.aboutMeWrapper {
  color: whitesmoke;
  background-color: #3127e8;
  min-height: 300px;
  text-align: center;
  margin-bottom: -7em;
}

.skillsContainer {
  position: relative;
  background-color: white;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  z-index: 5;
  margin-top: 0em;
  margin-bottom: 3em;
}

.projectThumbnail{
  max-width: 300px;
  max-height: 300px;
  border-radius: 150px;
}
